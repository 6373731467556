<template>
  <div id="main-container" class="mx-auto">
    <div id="main-container-header" class="d-flex flex-row align-center">
      <div id="main-container-logo">
        <v-img :src="require('../../assets/caixaqui/caixaaqui_pequeno.png')"></v-img>
      </div>
      <div id="main-container-title" class="d-flex align-center justify-center">
        <div>{{ title }}</div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div id="main-container-nav-left" class="d-flex flex-column justify-center">
        <router-link :to="menuLink" class="btn-azul" v-if="menuLink">Menu</router-link>
        <a @click="voltar" class="d-flex flex-column align-center my-1" v-if="showVoltar"
          ><v-img :src="require('../../assets/caixaqui/seta_voltar.png')" class="seta-volta"></v-img
        ></a>
        <a @click="sair" class="btn-laranja">{{ sairLabel }}</a>
      </div>
      <div id="main-container-content" class="">
        <slot></slot>
      </div>
      <div id="main-container-nav-right" class="d-flex flex-column justify-center">
        <a @click="proximo" class="d-flex flex-column align-center my-1" v-if="showProximo"
          ><v-img :src="require('../../assets/caixaqui/seta_avancar.png')" class="seta-avancar"></v-img
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CaixaquiFormTemplate",
  computed: {
    ...mapGetters("controller", ["getSairMenuLabel", "getMenuLink"]),
    title() {
      return this.$route.meta.title;
    },
    menuLink() {
      return this.getMenuLink(this.$route.meta.menuLink);
    },
    showVoltar() {
      return this.$route.meta.voltarLink;
    },
    showProximo() {
      return this.$route.meta.proximoLink;
    },
    sairLabel() {
      return this.getSairMenuLabel;
    }
  },
  methods: {
    ...mapActions("controller", ["menuSair"]),
    proximo() {
      this.$backForwardEvent.emit("onForward", this.$route.meta.proximoLink);
    },
    voltar() {
      this.$backForwardEvent.emit("onBackward", this.$route.meta.voltarLink);
    },
    sair() {
      this.menuSair({ router: this.$router });
    }
  }
};
</script>

<style scoped src="./caixaqui-style.css"></style>

<style scoped>
#main-container {
  width: 800px;
}

#main-container-header {
  margin-bottom: 16px;
}

#main-container-logo {
  width: 90px;
  height: 89px;
}

#main-container-title {
  width: 550px;
  height: 58px;
  border: 4px solid #0c469d;
  border-radius: 20px;
}

#main-container-title > div {
  color: #0c469d;
  font-family: Arial, serif;
  font-size: 24px;
  font-weight: bold;
  line-height: 50px;
}

#main-container-nav-left {
  width: 80px;
  height: 101px;
  margin-right: 8px;
}

#main-container-nav-right {
  width: 80px;
  height: 101px;
  margin-left: 8px;
}

#main-container-content {
  width: 600px;
}
</style>
