var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-auto", attrs: { id: "main-container" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex flex-row align-center",
          attrs: { id: "main-container-header" }
        },
        [
          _c(
            "div",
            { attrs: { id: "main-container-logo" } },
            [
              _c("v-img", {
                attrs: {
                  src: require("../../assets/caixaqui/caixaaqui_pequeno.png")
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "d-flex align-center justify-center",
              attrs: { id: "main-container-title" }
            },
            [_c("div", [_vm._v(_vm._s(_vm.title))])]
          )
        ]
      ),
      _c("div", { staticClass: "d-flex flex-row" }, [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column justify-center",
            attrs: { id: "main-container-nav-left" }
          },
          [
            _vm.menuLink
              ? _c(
                  "router-link",
                  { staticClass: "btn-azul", attrs: { to: _vm.menuLink } },
                  [_vm._v("Menu")]
                )
              : _vm._e(),
            _vm.showVoltar
              ? _c(
                  "a",
                  {
                    staticClass: "d-flex flex-column align-center my-1",
                    on: { click: _vm.voltar }
                  },
                  [
                    _c("v-img", {
                      staticClass: "seta-volta",
                      attrs: {
                        src: require("../../assets/caixaqui/seta_voltar.png")
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("a", { staticClass: "btn-laranja", on: { click: _vm.sair } }, [
              _vm._v(_vm._s(_vm.sairLabel))
            ])
          ],
          1
        ),
        _c(
          "div",
          { attrs: { id: "main-container-content" } },
          [_vm._t("default")],
          2
        ),
        _c(
          "div",
          {
            staticClass: "d-flex flex-column justify-center",
            attrs: { id: "main-container-nav-right" }
          },
          [
            _vm.showProximo
              ? _c(
                  "a",
                  {
                    staticClass: "d-flex flex-column align-center my-1",
                    on: { click: _vm.proximo }
                  },
                  [
                    _c("v-img", {
                      staticClass: "seta-avancar",
                      attrs: {
                        src: require("../../assets/caixaqui/seta_avancar.png")
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }