<template>
  <caixaqui-form-template>
    <router-view></router-view>
  </caixaqui-form-template>
</template>

<script>
import CaixaquiFormTemplate from "../../components/caixaqui/CaixaquiFormTemplate";

export default {
  name: "CaixaquiFormMain",
  components: {
    CaixaquiFormTemplate
  }
};
</script>

<style scoped></style>
